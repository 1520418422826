import React from "react";
import { Form, Input, Switch, Select } from "antd";

import { useLocation } from "react-router-dom";
import { Colorpicker } from "antd-colorpicker";
import { activeTab } from "../../../../helper/routeAndNavigation";
import { vendorAndTypeOptions } from "../../../../helper/vendor";
import { validateURL } from "../../../../helper/validate";

const AddWall = ({ setName, auth_info, vendors, forAdd }) => {
  const location = useLocation();
  return (
    <div>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Please input name!" },
          { max: 100, message: "Too Long" },
        ]}
        // getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input onChange={(e) => setName(e.target.value)} />
      </Form.Item>
      <Form.Item
        label="URL"
        name="url"
        rules={[
          { required: true, message: "Please input url!" },
          { validator: validateURL },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input />
      </Form.Item>
      {!auth_info.isVendor && forAdd ? (
        <Form.Item
          label="Vendor"
          name="vendorID"
          rules={[{ required: true, message: "Please select vendor!" }]}
        >
          <Select
            options={vendorAndTypeOptions(
              vendors,
              activeTab(location.pathname),
              false
            )}
          />
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item
        label="SKU Number"
        name="skuNumber"
        rules={[
          { required: true, message: "Please input SKU Number!" },
          { max: 50, message: "Too Long" },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input />
      </Form.Item>
      {!forAdd ? (
        <Form.Item label="Editable" name="editable" valuePropName="checked">
          <Switch checkedChildren="editable" unCheckedChildren="editable" />
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item
        label={"Color"}
        name={`color`}
        className={"colorPicker"}
        rules={[{ required: true, message: "Please input Color" }]}
      >
        <Colorpicker />
      </Form.Item>
    </div>
  );
};
export default AddWall;
