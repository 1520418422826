import React from "react";
import { Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
const HeaderButton = ({ route, text }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const openFloorTable = () => {
    if (location.pathname !== route) {
      navigate(route);
    }
  };
  return (
    <div>
      <Button
        style={{ marginLeft: text === "Logs" && "4rem" }}
        type={location.pathname === route ? "primary" : "dashed"}
        onClick={openFloorTable}
      >
        {text}
      </Button>
    </div>
  );
};

export default HeaderButton;
