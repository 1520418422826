import {useSelector} from "react-redux";
import React from "react";
import {Typography} from "antd";
import styles from "../Disk/disk.module.css";
import Chart from "react-apexcharts";

const Ram = () => {
    const {Title} = Typography;

    const auth_info = useSelector((state) => state.common.auth_info);
    const space_info = useSelector((state) => state.common.space_info);

    const options = {
        labels: [
            `Used-${space_info?.ram.used || 0}`,
            `Available-${space_info?.ram.available || 0}`,
            `Total-${space_info?.ram.total || 0}`
        ],
    };
    const series = [space_info?.ram.used, space_info?.ram.available];


    if (!auth_info.isVendor) {
        return <div className={styles.main}>
            <Title level={4}>Ram</Title>
            <Chart
                options={options}
                series={series}
                type="pie"
                width="380"
            />
        </div>
    }
    return <></>
}

export default Ram