import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import "../LogsChart/logsCharts.css";
import { set_min_width_header } from "../../../Redux/Actions/commonAction";
import { getBackendError } from "../../../Redux/Actions/transferDataAction";
const StillPhoto = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const { backendErrors, loading } = state;
  useEffect(() => {
    dispatch(getBackendError());
  }, []);
  const tableRef = useRef(null);
  const backendErrorsColumn = [
    {
      title: "ID",
      dataIndex: "key",
      sorter: (a, b) => b.key - a.key,
    },
    { title: "Code", dataIndex: "code" },
    { title: "Reason", dataIndex: "reason" },
    { title: "Category", dataIndex: "category" },
    { title: "Message", dataIndex: "message" },
    { title: "Exception", dataIndex: "exception" },
    {
      title: "StackTrace",
      dataIndex: "details",
      render: (record) => record?.stackTrace,
    },
  ];

  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  return (
    <div>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        ref={tableRef}
        size={"small"}
        className={"globalTable"}
        loading={loading.backendErrors}
        dataSource={backendErrors}
        columns={backendErrorsColumn}
        bordered
      />
    </div>
  );
};

export default StillPhoto;
