import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import "./modal.css";
import { useDispatch, useSelector } from "react-redux";
import { get_countries } from "../../Redux/Actions/transferDataAction";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { activeTab } from "../../helper/routeAndNavigation";
import { vendorAndTypeOptions } from "../../helper/vendor";
import { checkFilterRequests } from "../../helper/queryParamsAndResponseData";

const FilterModal = ({ open, setOpen }) => {
  const location = useLocation();
  const state = useSelector((state) => state.common);
  const { vendors, filterParams, countries, auth_info } = state;
  const dispatch = useDispatch();

  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  useEffect(() => {
    dispatch(get_countries());
  }, [dispatch]);
  const onFinish = (value) => {
    filterParams[activeTab(location.pathname)] = value;
    handleOk();
    checkFilterRequests(dispatch, filterParams, location.pathname);
  };
  const params = filterParams[activeTab(location.pathname)];
  const optionsForCountrySelect = [];
  optionsForCountrySelect.push({ label: "All", value: "" });
  if (countries.length) {
    countries.forEach((item) => {
      optionsForCountrySelect.push({ label: item, value: item });
    });
  }

  return (
    <div>
      <Modal
        title={`filter for all ${activeTab(location.pathname)}`}
        open={open}
        width={700}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            ...params,
            startDate: params?.startDate
              ? dayjs(params?.startDate, "YYYY-MM-DD")
              : null,
            endDate: params?.endDate
              ? dayjs(params?.endDate, "YYYY-MM-DD")
              : null,
          }}
        >
          <Form.Item label="City" name="city">
            <Input />
          </Form.Item>
          <Form.Item label="IP Address" name="ipAddress">
            <Input />
          </Form.Item>
          <Form.Item label="Country" name="country">
            <Select options={optionsForCountrySelect} />
          </Form.Item>
          {!auth_info.isVendor ? (
            <Form.Item label="Vendor" name="vendorID">
              <Select
                options={[
                  { label: "All", value: "" },
                  ...vendorAndTypeOptions(
                    vendors,
                    activeTab(location.pathname),
                    false
                  ),
                ]}
              />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            label="Start Date"
            name="startDate"
            help={"Filtering Dates by UTC time Zone"}
          >
            <DatePicker format={"YYYY/MM/DD"} />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            help={"Filtering Dates by UTC time Zone"}
          >
            <DatePicker format={"YYYY/MM/DD"} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Apply
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FilterModal;
