import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Table } from "antd";
import "../LogsChart/logsCharts.css";
import apiService from "../../../serviceUtils";
import {
  set_is_modal,
  set_min_width_header,
} from "../../../Redux/Actions/commonAction";
import ImageAndImageLoading from "../../imageAndImageLoading/imageAndImageLoading";
const StillPhoto = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const { requests, loading, floors, walls } = state;
  const tableRef = useRef(null);
  const stillPhotosColumn = [
    {
      title: "ID",
      dataIndex: "key",
      sorter: (a, b) => b.key - a.key,
    },
    {
      title: "Original Photo",
      dataIndex: "",
      render: (record) => {
        return (
          <>
            {record.originalPhotoName ? (
              <ImageAndImageLoading
                src={`${apiService.get_server_domain()}/still_images/${
                  record.originalPhotoName
                }.jpg`}
                alt={"Original"}
              />
            ) : (
              "Empty"
            )}
          </>
        );
      },
    },
    {
      title: "Result Image",
      dataIndex: "",
      render: (record) => {
        return (
          <>
            {record.photo_name ? (
              <ImageAndImageLoading
                src={`${apiService.get_server_domain()}/still_images/${
                  record.photo_name
                }.jpg`}
                alt={"Result"}
              />
            ) : (
              "Empty"
            )}
          </>
        );
      },
    },
    {
      title: "Time (Second)",
      dataIndex: "time",
    },
    {
      title: "Floor",
      dataIndex: "floorTileID",
      render: (record) => {
        if (record) {
          const floor = floors.find((floor) => floor.key === record);
          if (floor) {
            return (
              <ImageAndImageLoading
                src={floor.material ? floor.material : ""}
                alt={"Floor"}
              />
            );
          } else {
            return (
              <span>
                Vendor Paused or <br /> No Such Material
              </span>
            );
          }
        } else {
          return "Not Choosen";
        }
      },
    },
    {
      title: "Wall",
      dataIndex: "wallColorID",
      render: (record) => {
        if (record) {
          const wall = walls.find((wall) => wall.key === record);
          if (wall) {
            return (
              <div
                className={"colorDiv"}
                onClick={() =>
                  dispatch(
                    set_is_modal({ open: wall.color, type: "view_photo" })
                  )
                }
                style={{ background: wall.color }}
                title={wall.color}
              />
            );
          } else {
            return (
              <span>
                Vendor Paused or <br /> No Such Material
              </span>
            );
          }
        } else {
          return "Not Choosen";
        }
      },
    },
    {
      title: (
        <span>
          Wall Color ID (WCID), <br />
          <hr />
          Floor Tile ID (FTID)
        </span>
      ),
      dataIndex: "",
      render: (record) => {
        return (
          <>
            <p>WCID - {record.wallColorID ? record.wallColorID : "Empty"}</p>
            <hr />
            <p>FTID - {record.floorTileID ? record.floorTileID : "Empty"}</p>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Session ID (SID) <br />
          <hr /> IP Address(IA)
        </span>
      ),
      dataIndex: "",
      render: (record) => (
        <>
          <p>
            SID -{" "}
            {record.requestAttributes?.sessionID
              ? record.requestAttributes?.sessionID
              : "Empty"}
          </p>
          <hr />
          <p>
            IA -{" "}
            {record.requestAttributes?.ipAddress
              ? record.requestAttributes?.ipAddress
              : "Empty"}
          </p>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  return (
    <div className="logs">
      {loading.requests ? (
        <div className={"chartLoading"}>
          <Spin size={"large"} />
        </div>
      ) : (
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          ref={tableRef}
          size={"small"}
          className={"globalTable"}
          loading={loading.requests}
          dataSource={requests
            .filter(
              (item) =>
                item.type === "stillphoto" && item.status === "COMPLETED"
            )
            .reverse()}
          columns={stillPhotosColumn}
          bordered
        />
      )}
    </div>
  );
};

export default StillPhoto;
