import Auth from "../Components/auth/auth";
import Vendors from "../Components/vendors/vendors";
import Walls from "../Components/walls/walls";
import Floors from "../Components/floors/floors";
import Rugs from "../Components/rugs/rugs";
import WallDecor from "../Components/wallDecor/wallDecor";
import FloorDecors from "../Components/floorDecors/floorDecors";
import TableDecors from "../Components/tableDecors/tableDecors";
import Forms from "../Components/forms/forms";
import AffiliateLinks from "../Components/affiliateLinks/affiliateLinks";
import AffiliateIncomes from "../Components/affiliateIncome/affiliateIncomes";
import Logs from "../Components/logs/logs";
import React from "react";

export const activeTab = (pathname) => {
  return pathname.slice(1, pathname.length);
};

export const allRoutes = [
  { id: 1, path: "/auth", element: <Auth />, name: "Auth" },
  { id: 2, path: "/vendors", element: <Vendors />, name: "Vendors" },
  { id: 3, path: "/walls", element: <Walls />, name: "Walls" },
  { id: 4, path: "/floors", element: <Floors />, name: "Floors" },
  { id: 5, path: "/rugs", element: <Rugs />, name: "Rugs" },
  { id: 6, path: "/wallDecors", element: <WallDecor />, name: "Wall Decors" },
  {
    id: 7,
    path: "/floorDecors",
    element: <FloorDecors />,
    name: "Floor Decors",
  },
  {
    id: 8,
    path: "/tableDecors",
    element: <TableDecors />,
    name: "Table Decors",
  },
  { id: 9, path: "/forms", element: <Forms />, name: "Forms" },
  {
    id: 10,
    path: "/affiliateLinks",
    element: <AffiliateLinks />,
    name: "Affiliate Links",
  },
  {
    id: 11,
    path: "/affiliateIncomes",
    element: <AffiliateIncomes />,
    name: "Affiliate Incomes",
  },
  { id: 12, path: "/logs", element: <Logs />, name: "Logs" },
];
export const checkRoute = (pathname, navigate) => {
  if (
    !allRoutes.find(
      (route) => route.path === pathname && route.path !== "/auth"
    )
  ) {
    navigate("/walls");
  }
};
export const logsNavigation = [
  { id: 1, name: "Charts", activeTab: "charts" },
  { id: 2, name: "Table", activeTab: "table" },
  { id: 3, name: "Still Photo", activeTab: "stillphoto" },
  { id: 4, name: "Backend Errors", activeTab: "backendErrors" },
  { id: 5, name: "Debugging", activeTab: "debuggingInfo" },
  { id: 6, name: "Scraping", activeTab: "scraping" },
];
