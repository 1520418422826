import React from "react";
import {useSelector} from "react-redux";
import Chart from "react-apexcharts";
import {Typography} from "antd";
import styles from "./disk.module.css"


const Disk = () => {
    const {Title} = Typography;

    const auth_info = useSelector((state) => state.common.auth_info);
    const space_info = useSelector((state) => state.common.space_info);


    const options = {
        labels: [
            `Used-${space_info?.disk.used || 0}`,
            `Available-${space_info?.disk.available || 0}`,
            `Total-${space_info?.disk.total || 0}`
        ],
    };
    const series = [space_info?.disk.used, space_info?.disk.available];


    if (!auth_info.isVendor) {
        return <div className={styles.main}>
            <Title level={4}>Disk</Title>
            <Chart
                options={options}
                series={series}
                type="pie"
                width="380"
            />
        </div>
    }
    return <></>
}

export default Disk
