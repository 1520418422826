import React from "react";
import { Modal } from "antd";
import "./modal.css";

const ViewPhotoModal = ({ open, setOpen }) => {
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  return (
    <div>
      <Modal
        title={""}
        open={open}
        width={700}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <div className={"viewPhotoModalContent"}>
          {open[0] === "#" ? (
            <div
              style={{
                background: open,
                color: open[1] <= 5 ? "white" : "black",
              }}
            >
              {open}
            </div>
          ) : (
            <img src={open} alt="logo" width={500} />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ViewPhotoModal;
