import moment from "moment/moment";

export const base64ToBinary = async (url, filename) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename);
};
export const changeFileName = () => {
  const d = new Date();
  return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}${Math.floor(
    Math.random() * 50000
  )}.jpg`;
};
export const fullUrlToFileName = (url) => {
  return url.slice(
    url.length - url.split("").reverse().join("").indexOf("/"), //reverse string and find last "/"
    url.length - url.split("").reverse().join("").indexOf(".") - 1 // reverse string and find last "."
  );
};
export const yearAndMonthForAddCard = (value) => {
  const yearAndMonth = {
    month: value.slice(
      value.length - value.split("").reverse().join("").indexOf("/"),
      value.length
    ),
    year: value.slice(
      0,
      value.length - value.split("").join("").indexOf("/") + 1
    ),
  };
  const d = new Date();
  const yearNow = d.getFullYear();
  const monthNow = d.getMonth() + 1;
  if (+yearAndMonth.year > +yearNow) {
    return yearAndMonth;
  } else if (
    +yearAndMonth.year === +yearNow &&
    +yearAndMonth.month > +monthNow
  ) {
    return yearAndMonth;
  } else {
    return null;
  }
};
export const datePickerValueOnlyDate = (date) => {
  const checkDate = JSON.parse(JSON.stringify(date));
  return moment(checkDate).format("YYYY-MM-DD");
};

export const logsChartOptions = [
  { label: "last 1 month", value: 1 },
  { label: "last 2 months", value: 2 },
  { label: "last 3 months", value: 3 },
  { label: "last 4 months", value: 4 },
];
export const isFiltered = (params) => {
  if (params?.country !== "United States") {
    return true;
  }
  for (const property in params) {
    if (params[property] && property !== "country") {
      return true;
    }
  }
  return false;
};
export const calculateTotal = (data, label) => {
  let total = 0;
  data.forEach((item) => {
    if (item[label]) {
      if (
        label === "cost" ||
        label === "price" ||
        label === "impressionsCount" ||
        label === "clicksCount"
      ) {
        total = total + item[label];
      } else if (label === "name") {
        total = total + 1;
      } else {
        total = total + item[label].length;
      }
    }
  });
  return total;
};
export const tilesCount = 10;

export const optionsForMonth = () => {
  let arr = [];
  for (let i = 1; i <= 12; i++) {
    arr.push({ label: i, value: i });
  }
  return arr;
};
export const optionsForYear = () => {
  let arr = [];
  for (
    let i = new Date().getFullYear();
    i <= new Date().getFullYear() + 10;
    i++
  ) {
    arr.push({ label: i, value: i });
  }
  return arr;
};
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const wallTypes = [
  { label: "Color", value: "color" },
  { label: "Wallpaper", value: "wallpaper" },
];
export const checkSessionStorage = (filterParams, tablesPageInfo) => {
  if (!sessionStorage.getItem("filterParams")) {
    sessionStorage.setItem("filterParams", JSON.stringify(filterParams));
  }
  if (!sessionStorage.getItem("tablesPageInfo")) {
    sessionStorage.setItem("tablesPageInfo", JSON.stringify(tablesPageInfo));
  }
};

export const sewingPatternOptions = [
  { label: "Staggered (Random)", value: "staggered" },
  { label: "Checkerboard (Stack)", value: "checkerboard" },
  { label: "Flipped Checkerboard", value: "flipped" },
  { label: "Gap Checkerboard", value: "gap_checkboard" },
  { label: "1/2 Offset", value: "OFFSET2" },
  { label: "1/3 Offset", value: "OFFSET3" },
];
export const defaultPaginationInfo = {
  current: 1,
  pageSize: 10,
};

export const chartStateSeries = (data, dateArray, serieName, type) => {
  let arr = [];
  dateArray.forEach((date, index) => {
    arr.push([]);
    data.forEach((item) => {
      if (item.type === serieName) {
        if (item.dateFormatForChart === date) {
          if (type === "interval" && !item.errorStackTrace) {
            if (
              (new Date(item.statusCompleted) -
                new Date(item.taskAddedToQueue)) /
                1000 >
              5
            )
              arr[index].push(
                (new Date(item.statusCompleted) -
                  new Date(item.taskAddedToQueue)) /
                  1000
              );
          } else if (
            (type === "globalInterval" || type === "maximum") &&
            !item.errorStackTrace
          ) {
            arr[index].push(
              (new Date(item.statusCompleted) -
                new Date(item.taskAddedToQueue)) /
                1000
            );
          } else if (type === "global") {
            arr[index].push(1);
          }
        }
      } else if (serieName === "ERROR" && item.status === serieName) {
        if (item.dateFormatForChart === date && type === "global") {
          arr[index].push(1);
        }
      }
    });
  });
  if (type === "interval" || type === "globalInterval" || type === "maximum") {
    let newArr = [];
    arr.forEach((item) => {
      let x = 0;
      item.forEach((extraItem) => {
        if (item) {
          x = x + +extraItem;
        }
      });
      if (type === "maximum") {
        newArr.push(
          item.length
            ? Math.max(...item.map((e) => (e > 0 ? e : 0))).toFixed(1) + 1
            : 0
        );
      } else {
        newArr.push(x / item.length > 0 ? (x / item.length).toFixed(1) : 0);
      }
    });
    return newArr;
  } else {
    return arr.map((item) => item.length);
  }
};

export const subTypeValue = (value) => {
  return value.slice(
    value.length - value.split("").reverse().join("").indexOf("_"),
    value.length
  );
};
