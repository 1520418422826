import React from "react";
import { Modal, Table } from "antd";
import "./modal.css";

const ViewAlgoVersions = ({ open, setOpen }) => {
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  const verticalData = Object.keys(open).map((key) => ({
    key,
    value: open[key],
  }));
  const columns = [
    {
      title: "ALGO",
      dataIndex: "key",
      key: "key",
      render: (record) => <b>{record}</b>,
    },
    {
      title: "Version",
      dataIndex: "value",
      key: "value",
    },
  ];
  return (
    <div>
      <Modal
        title={`View Algo versions`}
        open={open}
        width={900}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Table
          dataSource={verticalData}
          columns={columns}
          pagination={false}
          rowKey="key"
        />
      </Modal>
    </div>
  );
};

export default ViewAlgoVersions;
