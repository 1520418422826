import React, { useState } from "react";
import { Button, Form, InputNumber, Modal, Typography } from "antd";
import { useDispatch } from "react-redux";
import { charge } from "../../Redux/Actions/transferDataAction";

const ChargeModal = ({ open, setOpen }) => {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const [validate, setValidate] = useState("");
  const [cost, setCost] = useState(0);
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  const onFinish = (value) => {
    if (value > 0 && Number.isInteger(value) && +value) {
      dispatch(charge({ cost: value, id: open.key }));
      setValidate("");
      handleOk();
    } else {
      setValidate("value should be positive integer");
    }
  };
  return (
    <div>
      <Modal
        title={`Charge Modal for ${open.name}`}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          // onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Cost"
            name="cost"
            rules={[
              {
                validator: (rule, value) => {
                  setCost(value);
                  if (value > 0 && Number.isInteger(value) && +value) {
                    setValidate("");
                  } else {
                    setValidate("value should be positive integer");
                  }
                },
                required: true,
                message: "Please input Cost!",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Text style={{ color: "red" }}>{validate}</Text>
          <Form.Item wrapperCol={{ offset: 11 }}>
            <Button
              type="primary"
              onClick={() => onFinish(cost)}
              disabled={!!validate}
              htmlType="submit"
            >
              Charge
            </Button>
          </Form.Item>
        </Form>
        vendor id - {open.key}
        <br />
        vendor name - {open.name}
        <br />
        Amount Used - {open.dueCost.toFixed(2)} $
      </Modal>
    </div>
  );
};

export default ChargeModal;
