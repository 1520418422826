import React, { useRef } from "react";
import { Form, Input, Select } from "antd";

import { useLocation } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons";
import { validateNumber, validateURL } from "../../../../helper/validate";
import { vendorAndTypeOptions } from "../../../../helper/vendor";
import { activeTab } from "../../../../helper/routeAndNavigation";

const AddRug = ({
  setName,
  auth_info,
  vendors,
  logo,
  setLogo,
  setLogoFile,
  handler_sphere,
  material,
  setMaterial,
  setMaterialFile,
  forAdd,
}) => {
  const location = useLocation();
  const fileInputRef = useRef();
  return (
    <div>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Please input name!" },
          { max: 100, message: "Too Long" },
        ]}
        // getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input onChange={(e) => setName(e.target.value)} />
      </Form.Item>
      <Form.Item
        label="URL"
        name="url"
        rules={[
          { required: true, message: "Please input url!" },
          { validator: validateURL },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input />
      </Form.Item>
      {!auth_info.isVendor && forAdd ? (
        <Form.Item
          label="Vendor"
          name="vendorID"
          rules={[{ required: true, message: "Please select vendor!" }]}
        >
          <Select
            options={vendorAndTypeOptions(
              vendors,
              activeTab(location.pathname),
              false
            )}
          />
        </Form.Item>
      ) : (
        ""
      )}
      <Form.Item
        label="SKU Number"
        name="skuNumber"
        rules={[
          { required: true, message: "Please input SKU Number!" },
          { max: 100, message: "Too Long" },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Price"
        name={["price", "amount"]}
        rules={[
          { required: true, message: "Please input Price!" },
          // { max: 50, message: "Too Long" },
          { validator: validateNumber },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input placeholder={"($)"} />
      </Form.Item>
      <Form.Item
        label="Width"
        name="width"
        rules={[
          { required: true, message: "Please input Width!" },
          // { max: 50, message: "Too Long" },
          { validator: validateNumber },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input placeholder={"(inch)"} />
      </Form.Item>
      <Form.Item
        label="Height"
        name="height"
        rules={[
          { required: true, message: "Please input Height!" },
          // { max: 50, message: "Too Long" },
          { validator: validateNumber },
        ]}
        getValueFromEvent={(e) => e.target.value.trim()}
      >
        <Input placeholder={"(inch)"} />
      </Form.Item>
      {forAdd ? (
        <div className={"selectFile"} style={{ marginLeft: "20%" }}>
          <label htmlFor="button-file-material">
            <input
              accept=".png, .jpg, .jpeg, .svg"
              id="button-file-material"
              style={{ display: "none" }}
              ref={fileInputRef}
              type="file"
              onChange={(e) => handler_sphere(e, "logo")}
            />
            {logo ? (
              <div>
                <img
                  src={logo}
                  alt="uploaded material"
                  className={"reloadImage"}
                />
              </div>
            ) : (
              <CloudUploadOutlined style={{ fontSize: "200%" }} />
            )}
          </label>
          {logo && (
            <span
              className={"imageX"}
              onClick={() => {
                setLogo(null);
                setLogoFile(null);
                fileInputRef.current.value = "";
              }}
            >
              x
            </span>
          )}
          {!logo ? <i style={{ margin: "20px" }}>Select Material</i> : ""}
        </div>
      ) : (
        <div className={"selectFile"} style={{ marginLeft: "20%" }}>
          <label
            htmlFor="button-file3"
            style={{
              border: "1px solid black",
              width: "80px",
              height: "80px",
              cursor: "pointer",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <input
              accept=".png, .jpg, .jpeg, .svg"
              id="button-file3"
              style={{ display: "none" }}
              type="file"
              onChange={(e) => handler_sphere(e, "material")}
            />
            {material ? (
              <div>
                <img
                  src={material}
                  alt="uploaded logo"
                  className={"reloadImage"}
                />
              </div>
            ) : (
              <CloudUploadOutlined style={{ fontSize: "200%" }} />
            )}
          </label>
          {material && (
            <span
              className={"imageX"}
              onClick={() => {
                setMaterial(null);
                setMaterialFile(null);
              }}
            >
              x
            </span>
          )}
          {!material ? (
            <i style={{ marginLeft: "20px" }}>Select Material</i>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
export default AddRug;
