import { ConfigProvider } from "antd";
import "./Assets/Fonts/fonts.css";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { set_response_notification } from "./Redux/Actions/commonAction";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "./Components/header/header";
import { checkSessionStorage } from "./helper/utils";
import { allRoutes, checkRoute } from "./helper/routeAndNavigation";
import { checkVendorType } from "./helper/vendor";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const {
    responseNotification,
    auth_info,
    filterParams,
    tablesPageInfo,
    serverIsDown,
  } = state;

  useEffect(() => {
    if (serverIsDown) {
      alert("server is restarting please reload after 30 second");
    }
  }, [serverIsDown]);
  useEffect(() => {
    if (responseNotification) {
      toast[responseNotification.type](responseNotification.text);
      dispatch(set_response_notification(""));
    }
  }, [responseNotification, dispatch]);
  if (window === "undefined") {
    alert("Turn On Cookies");
  }
  checkSessionStorage(filterParams, tablesPageInfo);
  useEffect(() => {
    if (!auth_info?.token) {
      navigate("/auth");
    } else {
      if (!auth_info.isVendor) {
        checkRoute(location.pathname, navigate);
      } else {
        let x = 0;
        allRoutes.forEach((route) => {
          if (checkVendorType(route.path, auth_info.type)) {
            if (!x) {
              if (location.pathname === route.path) {
                x = 1;
                return navigate(route.path);
              } else {
                return navigate(route.path);
              }
            }
          }
        });
      }
    }
  }, [auth_info]);
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#4DB671",
            fontFamily: "Azo-Sans, sans-serif",
          },
        }}
      >
        {location.pathname !== "/auth" ? <Header /> : ""}
        <Routes>
          {allRoutes.map((route) => {
            return (
              <Route key={route.id} path={route.path} element={route.element} />
            );
          })}
        </Routes>
        <Toaster />
      </ConfigProvider>
    </>
  );
}

export default App;
