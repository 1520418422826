import { SET_RUGS } from "../Actions/rugs";
import {
  SET_FLOOR_DECOR,
  SET_TABLE_DECOR,
  SET_WALL_DECOR,
} from "../Actions/decor";
import { initialParamsForFilter } from "../../helper/queryParamsAndResponseData";

const initialState = {
  wallDecors: [],
  floorDecors: [],
  rugs: [],
  tableDecors: [],
  rugFilterParams: sessionStorage.getItem("rugFilterParams")
    ? JSON.parse(sessionStorage.getItem("rugFilterParams"))
    : initialParamsForFilter,
  wallDecorFilterParams: sessionStorage.getItem("wallDecorFilterParams")
    ? JSON.parse(sessionStorage.getItem("wallDecorFilterParams"))
    : initialParamsForFilter,
  floorDecorFilterParams: sessionStorage.getItem("floorDecorFilterParams")
    ? JSON.parse(sessionStorage.getItem("floorDecorFilterParams"))
    : initialParamsForFilter,
  tableDecorFilterParams: sessionStorage.getItem("tableDecorFilterParams")
    ? JSON.parse(sessionStorage.getItem("tableDecorFilterParams"))
    : initialParamsForFilter,
};
export const decorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WALL_DECOR:
      return { ...state, wallDecors: [...action.payload] };
    case SET_FLOOR_DECOR:
      return { ...state, floorDecors: [...action.payload] };
    case SET_RUGS:
      return { ...state, rugs: [...action.payload] };
    case SET_TABLE_DECOR:
      return { ...state, tableDecors: [...action.payload] };
    default:
      return state;
  }
};

export default decorsReducer;
