import { Button } from "antd";
import { set_is_modal } from "../Redux/Actions/commonAction";
import React, { useRef } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";

export const showDetails = (record, type, dispatch) => {
  let data;
  if (record === "records") {
    data = record[type]?.length;
  } else {
    data = record[type];
  }
  return (
    <div
      className={"impressionsRow"}
      style={{
        cursor: data ? "pointer" : "default",
      }}
    >
      <span>{data}</span>
      {data ? (
        <Button
          size={"small"}
          onClick={() => {
            dispatch(
              set_is_modal({
                open: {
                  ...record,
                  type,
                },
                type:
                  type === "records"
                    ? "recordsDetail"
                    : "impressions_and_clicks",
              })
            );
          }}
        >
          View
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};
export const TileInput = ({
  count,
  materials,
  materialFiles,
  setMaterials,
  setMaterialFiles,
  floorGlobalType,
  handler_sphere,
}) => {
  const fileInputRef = useRef();
  return (
    <div className={"selectFile"} style={{ marginLeft: "20%" }}>
      <label htmlFor={`button-file${count}`}>
        <input
          accept=".png, .jpg, .jpeg, .svg"
          id={`button-file${count}`}
          style={{ display: "none" }}
          ref={fileInputRef}
          type="file"
          onChange={(e) => handler_sphere(e, "material", count)}
        />
        {materials[count] ? (
          <div>
            <img
              src={materials[count]}
              alt="uploaded logo"
              className={"reloadImage"}
            />
          </div>
        ) : (
          <CloudUploadOutlined style={{ fontSize: "200%" }} />
        )}
      </label>
      {materials[count] && (
        <span
          className={"imageX"}
          onClick={() => {
            materialFiles.splice(count, 1);
            materials.splice(count, 1);
            setMaterials([...materials]);
            setMaterialFiles([...materialFiles]);
            fileInputRef.current.value = "";
          }}
        >
          x
        </span>
      )}
      {(!floorGlobalType || floorGlobalType === "carpet") &&
        !materials[count] && (
          <i style={{ marginLeft: "20px" }}>Select Material</i>
        )}
    </div>
  );
};
