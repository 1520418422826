import React, { useState } from "react";
import { set_is_modal } from "../../Redux/Actions/commonAction";
import { Spin } from "antd";
import { useDispatch } from "react-redux";

const ImageAndImageLoading = ({ src, alt }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  if (loading) {
    return (
      <>
        <Spin />
        <img
          src={src}
          onLoad={() => setLoading(false)}
          onError={() => setLoading(false)}
          alt={alt ? alt : "material logo"}
          style={{ display: "none" }}
        />
      </>
    );
  } else {
    return (
      <img
        src={src}
        style={{ cursor: "pointer" }}
        onClick={() =>
          src && dispatch(set_is_modal({ open: src, type: "view_photo" }))
        }
        onLoad={() => setLoading(false)}
        onError={() => setLoading(false)}
        alt={alt ? alt : "material logo"}
        width={30}
      />
    );
  }
};

export default ImageAndImageLoading;
