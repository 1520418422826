import axios from "axios";

export const SET_RESPONSE_NOTIFICATION = "SET_RESPONSE_NOTIFICATION";
export const SET_LOADING = "SET_LOADING";
export const SET_IS_MODAL = "SET_IS_MODAL";
export const SET_FILTER_PARAMS = "SET_FILTER_PARAMS";
export const SET_FORMS = "SET_FORMS";
export const SET_AFFILIATE_LINKS = "SET_AFFILIATE_LINKS";
export const SET_AFFILIATE_INCOMES = "SET_AFFILIATE_INCOMES";
export const SET_REQUESTS = "SET_REQUESTS";
export const SET_BACKEND_ERRORS = "SET_BACKEND_ERRORS";
export const SET_DEBUGGING_INFO = "SET_DEBUGGING_INFO";
export const SET_SPACE_INFO = "SET_SPACE_INFO";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_IMPRESSIONS = "SET_IMPRESSIONS";
export const SET_CLICKS = "SET_CLICKS";
export const SET_VIEW_ALL_PROCESS = "SET_VIEW_ALL_PROCESS";
export const SET_MATERIAL_ID_FOR_PROCESSING = "SET_MATERIAL_ID_FOR_PROCESSING";
export const SET_ACTIVE_TAB_IN_LOGS = "SET_ACTIVE_TAB_IN_LOGS";
export const SET_CHART_FILTER = "SET_CHART_FILTER";
export const SET_TABLES_PAGE_INFO = "SET_TABLES_PAGE_INFO";
export const SET_MIN_WIDTH_HEADER = "SET_MIN_WIDTH_HEADER";
export const SET_IN_PROGRESS_MATERIALS = "SET_IN_PROGRESS_MATERIALS";
export const SET_SERVER_IS_DOWN = "SET_SERVER_IS_DOWN";
export const SET_TESTING_MODE = "SET_TESTING_MODE";

export const set_loading = (payload) => {
  return {
    type: SET_LOADING,
    payload,
  };
};
export const set_is_modal = (payload) => {
  return {
    type: SET_IS_MODAL,
    payload,
  };
};

export const set_response_notification = (payload) => {
  return {
    type: SET_RESPONSE_NOTIFICATION,
    payload,
  };
};

export const set_forms = (payload) => {
  return {
    type: SET_FORMS,
    payload,
  };
};
export const setAffiliateLinks = (payload) => {
  return {
    type: SET_AFFILIATE_LINKS,
    payload,
  };
};
export const setAffiliateIncomes = (payload) => {
  return {
    type: SET_AFFILIATE_INCOMES,
    payload,
  };
};
export const set_requests = (payload) => {
  return {
    type: SET_REQUESTS,
    payload,
  };
};
export const set_backendErrors = (payload) => {
  return {
    type: SET_BACKEND_ERRORS,
    payload,
  };
};
export const setDebuggingInfo = (payload) => {
  return {
    type: SET_DEBUGGING_INFO,
    payload,
  };
};

export const setFilterParams = (payload) => {
  sessionStorage.setItem("filterParams", JSON.stringify({ ...payload }));
  return {
    type: SET_FILTER_PARAMS,
    payload,
  };
};
export const set_space_info = (payload) => {
  return {
    type: SET_SPACE_INFO,
    payload,
  };
};
export const set_countries = (payload) => {
  return {
    type: SET_COUNTRIES,
    payload,
  };
};
export const setImpressions = (payload) => {
  return {
    type: SET_IMPRESSIONS,
    payload,
  };
};
export const setClicks = (payload) => {
  return {
    type: SET_CLICKS,
    payload,
  };
};

export const set_material_id_for_processing = (payload) => {
  return {
    type: SET_MATERIAL_ID_FOR_PROCESSING,
    payload,
  };
};
export const set_active_tab_in_logs = (payload) => {
  return {
    type: SET_ACTIVE_TAB_IN_LOGS,
    payload,
  };
};
export const set_chart_filter = (payload) => {
  return {
    type: SET_CHART_FILTER,
    payload,
  };
};
export const set_tablesPageInfo = (payload) => {
  sessionStorage.setItem("tablesPageInfo", JSON.stringify({ ...payload }));
  return {
    type: SET_TABLES_PAGE_INFO,
    payload,
  };
};
export const set_min_width_header = (payload) => {
  return {
    type: SET_MIN_WIDTH_HEADER,
    payload,
  };
};

export const setInProgressMaterialLoading = (payload) => {
  return {
    type: SET_IN_PROGRESS_MATERIALS,
    payload,
  };
};
export const setServerIsDown = (payload) => {
  return {
    type: SET_SERVER_IS_DOWN,
    payload,
  };
};
export const setTestingMode = (payload) => {
  return {
    type: SET_TESTING_MODE,
    payload,
  };
};
export const fetchingDataWithAxiosMiddleware = async (
  method,
  url,
  formData
) => {
  const handlerObjectForSend = {
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${
        localStorage.getItem("auth_info")
          ? JSON.parse(localStorage.getItem("auth_info"))?.token
          : ""
      }`,
    },
  };
  if (method !== "GET" && method !== "DELETE") {
    handlerObjectForSend.data = formData;
  }
  return axios(handlerObjectForSend);
};
