import { setFilterParams } from "../Redux/Actions/commonAction";
import { get_filter_walls } from "../Redux/Actions/wall";
import { get_filter_floors } from "../Redux/Actions/floor";
import { getAllRugs } from "../Redux/Actions/rugs";
import { getDecors } from "../Redux/Actions/decor";
import { get_forms } from "../Redux/Actions/transferDataAction";
import { datePickerValueOnlyDate, monthNames } from "./utils";
import { onlyVendorTypes } from "./vendor";

export const queryParamsForCommonFilter = (data) => {
  const city = data.city ? `city=${data.city}&` : "";
  const country = data.country ? `country=${data.country}&` : "";
  const vendorID = data.vendorID ? `vendorID=${data.vendorID}&` : "";
  const ipAddress = data.ipAddress ? `ipAddress=${data.ipAddress}&` : "";
  const startDate = data.startDate
    ? `startDate=${datePickerValueOnlyDate(data.startDate)}&`
    : "";
  const endDate = data.endDate
    ? `endDate=${datePickerValueOnlyDate(data.endDate)}`
    : "";
  let params = city + country + vendorID + ipAddress + startDate + endDate;
  return params[params.length - 1] === "&"
    ? params.slice(0, params.length - 1)
    : params;
};

export const changeResponseDate = (data, type) => {
  const arr = [];
  if (type === "walls") {
    data.forEach((item) => {
      arr.push({
        ...item,
        key: item.id,
        color: item.hexCode,
        url: item.urlOnVendorPage,
      });
    });
  } else if (type === "floors") {
    data.forEach((item) => {
      arr.push({
        ...item,
        key: item.id,
        material: item.thumbnailURL,
        url: item.urlOnVendorPage,
        tiles: item.materials,
      });
    });
  } else if (type === "vendors" || type === "backendErrors") {
    data.forEach((item) => {
      arr.push({
        ...item,
        types:
          type === "vendors" ? onlyVendorTypes(item.materialCountByType) : "",
        key: item.id,
      });
    });
  } else if (type === "forms") {
    data.forEach((item) => {
      arr.push({ ...item, key: item.id, dataAndTime: item.timestamp });
    });
  } else if (type === "requests") {
    data.forEach((item) => {
      const time =
        item.statusCompleted && item.taskAddedToQueue
          ? (
              (new Date(item.statusCompleted)?.getTime() -
                new Date(item.taskAddedToQueue)?.getTime()) /
              1000
            )?.toFixed(2)
          : null;
      arr.push({
        ...item,
        key: item.id,
        dateFormatForChart: `${new Date(item.taskAddedToQueue).getDate()}/${
          monthNames[new Date(item.taskAddedToQueue).getMonth()]
        }`,
        time: time ? time : "",
      });
    });
  } else if (type === "affiliateLinks") {
    data.forEach((item) => {
      arr.push({ key: item.id, code: item.key, ratio: item.ratio });
    });
  } else if (type === "affiliateIncomes") {
    data.forEach((item) => {
      arr.push({ ...item, key: item.affiliateId });
    });
  }

  return arr;
};
export const checkFilterRequests = (dispatch, filterParams, pathname) => {
  dispatch(setFilterParams({ ...filterParams }));
  if (pathname === "/walls") {
    dispatch(get_filter_walls());
  } else if (pathname === "/floors") {
    dispatch(get_filter_floors());
  } else if (pathname === "/rugs") {
    dispatch(getAllRugs());
  } else if (pathname === "/wallDecors") {
    dispatch(getDecors("wall"));
  } else if (pathname === "/floorDecors") {
    dispatch(getDecors("floor"));
  } else if (pathname === "/tableDecors") {
    dispatch(getDecors("table"));
  } else if (pathname === "/forms") {
    dispatch(get_forms());
  }
};
export const initialParamsForFilter = {
  city: "",
  ipAddress: "",
  startDate: "",
  country: "United States",
  endDate: "",
  vendorID: "",
};
export const commonFilter = (page, forRemove) => {
  const params = page === "vendors" ? [] : { ...initialParamsForFilter };
  if (forRemove) {
    return params;
  } else {
    return sessionStorage.getItem("filterParams")
      ? JSON.parse(sessionStorage.getItem("filterParams"))[page]
      : params;
  }
};
