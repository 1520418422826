import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_credit_card,
  save_credit_card,
} from "../../Redux/Actions/creditCard";
import { yearAndMonthForAddCard } from "../../helper/utils";

const AddCard = ({ open, setOpen }) => {
  const state = useSelector((state) => state.common);
  const { auth_info } = state;
  const dispatch = useDispatch();

  const [cardNumberValue, setCardNumberValue] = useState("0000 0000 0000 0000");
  const [creditCardNumber, setCreditCardNumbers] = useState("");
  const [creditCardNumber_type2, setCreditCardNumbers_type2] = useState({
    first: "",
    second: "",
    third: "",
    forth: "",
  });

  useEffect(() => {
    setNumber(
      `${creditCardNumber_type2.first}${creditCardNumber_type2.second}${creditCardNumber_type2.third}${creditCardNumber_type2.forth}`
    );
  }, [creditCardNumber_type2]);

  const refFirstCardNumber = useRef(null),
    refSecondCardNumber = useRef(null),
    refThirdCardNumber = useRef(null),
    refForthCardNumber = useRef(null);

  const handlerCreditCardNumber = (index, value = "") => {
    setCreditCardNumbers_type2((prev) =>
      index === 1
        ? { ...prev, first: value }
        : index === 2
        ? { ...prev, second: value }
        : index === 3
        ? { ...prev, third: value }
        : { ...prev, forth: value }
    );
  };

  const creditCardInputStyles = {
    margin: "0 5px 0 0",
    padding: "0",
    border: "none",
    outline: "none",
    width: "auto",
    maxWidth: "48px",
    fontSize: "20px",
    background: "transparent",
  };

  useEffect(() => {
    if (refSecondCardNumber.current) {
      if (creditCardNumber_type2.first.length === 4) {
        refSecondCardNumber.current.focus();
      }
    }
    if (refThirdCardNumber.current) {
      if (
        creditCardNumber_type2.second.length === 4 &&
        creditCardNumber_type2.first.length === 4
      ) {
        refThirdCardNumber.current.focus();
      }
    }
    if (refForthCardNumber.current) {
      if (
        creditCardNumber_type2.third.length === 4 &&
        creditCardNumber_type2.second.length === 4 &&
        creditCardNumber_type2.first.length === 4
      ) {
        refForthCardNumber.current.focus();
      }
    }
  }, [refSecondCardNumber, creditCardNumber_type2]);

  const handlerCreditCardInputKeyDown = (event, ref_check, ref_set) => {
    if (event.key === "Backspace") {
      if (ref_check.current.value.length === 0) {
        ref_set.current.focus();
      }
    }
    if (event.key === "Delete") {
      if (ref_check.current.value.length === 0) {
        ref_set.current.focus();
      }
    }
  };

  const [cardHolderNameValue, setCardHolderNameValue] = useState("");
  const [cardExpirationDateValue, setCardExpirationDateValue] = useState("");
  const [cardCVVValue, setCardCVVValue] = useState("");
  const [cardTypeValue, setCardTypeValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [isFront, setIsFront] = useState(true);
  const [disableAdd, setDisableAdd] = useState(true);
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };

  const onFinish = () => {
    if (!auth_info.vendor.stripeCreditCardID) {
      if (!disableAdd) {
        dispatch(
          save_credit_card({
            email: emailValue,
            cardNumber: cardNumberValue,
            nameOnCard: cardHolderNameValue,
            expirationMonth: yearAndMonthForAddCard(cardExpirationDateValue)
              .month,
            expirationYear: yearAndMonthForAddCard(cardExpirationDateValue)
              .year,
            cvv: cardCVVValue,
          })
        );
        handleOk();
      }
    } else {
      dispatch(delete_credit_card());
      handleOk();
    }
  };

  const flipCard = () => {
    setIsFront(false);
  };
  const unFlipCard = () => {
    setIsFront(true);
  };

  const checkSubstring = (length, match) => {
    return cardNumberValue.substring(0, length) === match;
  };

  const setNumber = (value) => {
    setCreditCardNumbers(value);
    setCardNumberValue(value);
    // setCardNumberValueV1(value)
    if (cardNumberValue[0] === "4") {
      setCardTypeValue("Visa");
    } else if (checkSubstring(4, "6011")) {
      setCardTypeValue("Discover");
    } else if (checkSubstring(2, "51")) {
      setCardTypeValue("MasterCard");
    } else if (checkSubstring(2, "34")) {
      setCardTypeValue("American Express");
    } else if (checkSubstring(3, "300")) {
      setCardTypeValue("Diners Club");
    } else if (checkSubstring(2, "35")) {
      setCardTypeValue("JCB");
    } else {
      setCardTypeValue("💳");
    }
  };
  const setName = (e) => {
    const cardHolderName = e.target.value.toUpperCase();
    setCardHolderNameValue(cardHolderName);
  };
  const setEmail = (e) => {
    setEmailValue(e.target.value);
  };
  const setDate = (e) => {
    let data = e.target.value.split("");
    let cardExpirationDate = data
      .map((x) => {
        return x === "-" ? "/" : x;
      })
      .join("");
    setCardExpirationDateValue(cardExpirationDate);
  };
  const setCVV = (e) => {
    setCardCVVValue(e.target.value);
  };

  useEffect(() => {
    setDisableAdd(
      !(
        creditCardNumber.length === 16 &&
        cardHolderNameValue.length &&
        yearAndMonthForAddCard(cardExpirationDateValue) &&
        cardCVVValue.length === 3 &&
        emailValue.length > 5
      )
    );
  }, [
    creditCardNumber,
    cardHolderNameValue,
    cardExpirationDateValue,
    cardCVVValue,
    emailValue,
  ]);

  return (
    <div>
      <Modal
        title={
          !auth_info?.vendor?.stripeCreditCardID ? "Add Card" : "Delete Card"
        }
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        {!auth_info?.vendor?.stripeCreditCardID ? (
          <div className="container">
            <div className="credit-card">
              <div
                className={`credit-card-inner ${
                  isFront ? "credit-card-inner-front" : "credit-card-inner-back"
                }`}
              >
                <div className="credit-card-front">
                  <div id="card-type">{cardTypeValue}</div>
                  <div id="card-number">
                    {`${
                      creditCardNumber_type2.first || "0000 0000 0000 0000"
                    } `}
                    {`${creditCardNumber_type2.second} `}
                    {`${creditCardNumber_type2.third} `}
                    {`${creditCardNumber_type2.forth} `}
                  </div>

                  <div id="card-expiration">
                    {cardExpirationDateValue !== "" && (
                      <div id="validthru">Valid Thru</div>
                    )}
                    {cardExpirationDateValue}
                  </div>

                  <div id="card-holder-name">{cardHolderNameValue}</div>
                </div>
                <div className="credit-card-back">
                  <div className="card-stripe" />
                  <div className="card-sig-container">
                    {/*<div className="signature">{cardHolderNameValue}</div>*/}
                    <span
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0 15px",
                      }}
                    >
                      <span>CVC</span>
                      <span>{cardCVVValue}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <form className="card-form">
              <label className="input-label" htmlFor={"text-input-email"}>
                Email
              </label>
              <input
                placeholder="Enter your email"
                id="text-input-email"
                name="email-input"
                type={"email"}
                className="text-input"
                onChange={setEmail}
              />
              <label className="input-label" htmlFor={"number-input"}>
                Credit Card Number
              </label>
              <div
                onClick={() => {
                  const { forth, third, second, first } =
                    creditCardNumber_type2;
                  if (first.length === 4) {
                    if (second.length < 4) {
                      refSecondCardNumber.current.focus();
                    } else if (third.length < 4) {
                      refThirdCardNumber.current.focus();
                    } else if (forth.length < 4) {
                      refForthCardNumber.current.focus();
                    }
                  }
                }}
                style={{
                  position: "relative",
                  width: "calc(100% - 35px)",
                  padding: "15px 15px 15px 0",
                  margin: "5px 5px 10px 5px",
                  borderBottom: "1px solid #ff0866",
                }}
              >
                <label
                  htmlFor="first-numbers"
                  style={
                    !creditCardNumber_type2.first &&
                    !creditCardNumber_type2.second &&
                    !creditCardNumber_type2.third &&
                    !creditCardNumber_type2.forth
                      ? {
                          position: "absolute",
                          left: "2px",
                          fontSize: "20px",
                          fontWeight: "100",
                          fontFamily: "Azo-Sans, sans-serif !important",
                          textTransform: "none",
                          color: "gray",
                          height: "50px",
                        }
                      : { display: "none" }
                  }
                >
                  Enter your credit card number
                </label>
                <input
                  ref={refFirstCardNumber}
                  style={creditCardInputStyles}
                  type="text"
                  onChange={(e) => handlerCreditCardNumber(1, e.target.value)}
                  id="first-numbers"
                  maxLength="4"
                />
                <input
                  ref={refSecondCardNumber}
                  onKeyDown={(e) =>
                    handlerCreditCardInputKeyDown(
                      e,
                      refSecondCardNumber,
                      refFirstCardNumber
                    )
                  }
                  style={creditCardInputStyles}
                  type="text"
                  onChange={(e) => handlerCreditCardNumber(2, e.target.value)}
                  id="second-numbers"
                  maxLength="4"
                />
                <input
                  ref={refThirdCardNumber}
                  onKeyDown={(e) =>
                    handlerCreditCardInputKeyDown(
                      e,
                      refThirdCardNumber,
                      refSecondCardNumber
                    )
                  }
                  style={creditCardInputStyles}
                  type="text"
                  onChange={(e) => handlerCreditCardNumber(3, e.target.value)}
                  id="third-numbers"
                  maxLength="4"
                />
                <input
                  ref={refForthCardNumber}
                  onKeyDown={(e) =>
                    handlerCreditCardInputKeyDown(
                      e,
                      refForthCardNumber,
                      refThirdCardNumber
                    )
                  }
                  style={creditCardInputStyles}
                  type="text"
                  onChange={(e) => handlerCreditCardNumber(4, e.target.value)}
                  id="forth-numbers"
                  maxLength="4"
                />
              </div>
              <label className="input-label" htmlFor={"text-input-name"}>
                Card Holder Name
              </label>
              <input
                type="text"
                placeholder="Enter card holder name"
                value={cardHolderNameValue}
                onChange={(e) => setName(e)}
                className="text-input"
                id={"text-input-name"}
                maxLength="30"
              />
              <div className="date-and-csv" style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <label className="input-label" htmlFor={"text-input-date"}>
                    Expiration Date
                  </label>
                  <input
                    type="month"
                    placeholder="Enter expiration date"
                    className="text-input"
                    id={"text-input-date"}
                    onChange={(e) => setDate(e)}
                    style={{
                      // height: "23px",
                      // fontSize: "16px",
                      fontWeight: "100",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <label className="input-label" htmlFor={"text-input-cvc"}>
                    CVC Security Code
                  </label>
                  <input
                    placeholder="Enter CVC"
                    maxLength="3"
                    value={cardCVVValue}
                    id={"text-input-cvc"}
                    className="text-input"
                    onChange={(e) => setCVV(e)}
                    onFocus={flipCard}
                    onBlur={unFlipCard}
                  />
                </div>
              </div>
              <Button type="primary" onClick={onFinish} disabled={disableAdd}>
                Add
              </Button>
            </form>
          </div>
        ) : (
          <Button type="primary" onClick={onFinish}>
            Delete
          </Button>
        )}
      </Modal>{" "}
    </div>
  );
};

export default AddCard;
