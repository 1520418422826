import React, { useState } from "react";
import { Button, Form, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./modal.css";
import { edit_vendor } from "../../Redux/Actions/vendor";
import { edit_floor } from "../../Redux/Actions/floor";
import { edit_wall } from "../../Redux/Actions/wall";
import { useLocation } from "react-router-dom";
import { editRug } from "../../Redux/Actions/rugs";
import { editDecor } from "../../Redux/Actions/decor";
import VendorContent from "./content/vendors/vendorContent";
import WallContent from "./content/walls/wallContent";
import FloorContent from "./content/floors/floorContent";
import RugContent from "./content/rugs/rugContent";
import DecorContent from "./content/decors/decorContent";
import { activeTab } from "../../helper/routeAndNavigation";
import { changeFileName, subTypeValue } from "../../helper/utils";

const EditModal = ({ open, setOpen }) => {
  const location = useLocation();
  const state = useSelector((state) => state.common);
  const { vendors, auth_info } = state;
  const dispatch = useDispatch();

  const [logo, setLogo] = useState(open.logo);
  const [material, setMaterial] = useState(open.material);
  const [logoFile, setLogoFile] = useState(null);
  const [materialFile, setMaterialFile] = useState(null);
  const [validateText, setValidateText] = useState("");

  const [costPerView, setCostPerView] = useState(
    open.costPerView?.length ? open.costPerView.map((item) => `${item}`) : [0]
  );
  const [name, setName] = useState("");

  const costPerViewList = (costPerView) => {
    let arr = [];
    costPerView.forEach((item) => {
      if (item) {
        arr.push(+item);
      }
    });
    return arr;
  };
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  const onFinish = (value) => {
    if (location.pathname === "/vendors") {
      if (logoFile || logo) {
        dispatch(
          edit_vendor({
            ...value,
            key: open.key,
            logo: logoFile,
            costPerView: costPerViewList(costPerView),
            impressionLimit: costPerViewList(costPerView).length,
          })
        );
        handleOk();
      }
    } else if (location.pathname === "/floors") {
      if (material || materialFile) {
        dispatch(
          edit_floor({
            key: open.key,
            name: value.name,
            code: value.code,
            url: value.url,
            vendorID: value.vendorID,
            material: materialFile,
            editable: value.editable,
          })
        );
        handleOk();
      }
    } else if (location.pathname === "/walls") {
      dispatch(
        edit_wall({
          key: open.key,
          name: value.name,
          url: value.url,
          color: value.color.hex,
          vendorID: value.vendorID,
          skuNumber: value.skuNumber,
          editable: value.editable,
        })
      );
      handleOk();
    } else if (location.pathname === "/rugs") {
      if (material || materialFile) {
        dispatch(
          editRug({
            ...value,
            price: value.price.amount,
            material: materialFile,
            id: open.key,
          })
        );
        handleOk();
      }
    } else {
      if (material || materialFile) {
        dispatch(
          editDecor({
            ...value,
            price: value.price.amount,
            material: materialFile,
            id: open.key,
            type:
              location.pathname === "/wallDecors"
                ? "WALL"
                : location.pathname === "/floorDecors"
                ? "FLOOR"
                : location.pathname === "/tableDecors"
                ? "TABLE"
                : "",
            subType: subTypeValue(value.subType),
          })
        );
        handleOk();
      }
    }
    setValidateText("Please Upload a Picture");
  };

  const handler_sphere = (event, type) => {
    if (event.target && event.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      const renameFile = new File(
        [event.target.files[0]],
        `${type}-${changeFileName()}`
      );
      reader.addEventListener("load", () => {
        if (type === "material") {
          setMaterialFile(renameFile);
          setMaterial(reader.result);
        } else {
          setLogoFile(renameFile);
          setLogo(reader.result);
        }
      });
    }
  };
  return (
    <div>
      <Modal
        title={`Edit Modal for ${activeTab(location.pathname)}`}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={open}
          onFinish={onFinish}
          autoComplete="off"
        >
          <h2 style={{ color: "red" }}>{validateText}</h2>
          {location.pathname === "/vendors" ? (
            <VendorContent
              costPerView={costPerView}
              setCostPerView={setCostPerView}
              logo={logo}
              setLogo={setLogo}
              costPerViewList={costPerViewList}
              setLogoFile={setLogoFile}
              location={location}
              handler_sphere={handler_sphere}
              setName={setName}
              forAdd={false}
            />
          ) : location.pathname === "/walls" ? (
            <WallContent
              auth_info={auth_info}
              setName={setName}
              vendors={vendors}
              forAdd={false}
            />
          ) : location.pathname === "/floors" ? (
            <FloorContent
              vendors={vendors}
              setName={setName}
              auth_info={auth_info}
              name={name}
              handler_sphere={handler_sphere}
              forAdd={false}
              material={material}
              setMaterial={setMaterial}
              setMaterialFile={setMaterialFile}
            />
          ) : location.pathname === "/rugs" ? (
            <RugContent
              auth_info={auth_info}
              setName={setName}
              vendors={vendors}
              logo={logo}
              setLogo={setLogo}
              setLogoFile={setLogoFile}
              handler_sphere={handler_sphere}
              forAdd={false}
              material={material}
              setMaterial={setMaterial}
              setMaterialFile={setMaterialFile}
            />
          ) : location.pathname === "/wallDecors" ||
            location.pathname === "/floorDecors" ||
            location.pathname === "/tableDecors" ? (
            <DecorContent
              setName={setName}
              logo={logo}
              setLogo={setLogo}
              setLogoFile={setLogoFile}
              // selectOnChange={selectOnChange}
              auth_info={auth_info}
              vendors={vendors}
              handler_sphere={handler_sphere}
              forAdd={false}
              material={material}
              setMaterial={setMaterial}
              setMaterialFile={setMaterialFile}
            />
          ) : (
            ""
          )}
          <Form.Item wrapperCol={{ offset: 11 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditModal;
