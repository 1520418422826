import { activeTab } from "./routeAndNavigation";

export const showColumns = (columns, vendor) => {
  if (!vendor) {
    columns = columns.filter((item) => item.forVendor);
  }
  return [...columns];
};
export const vendorTypes = [
  { label: "WALL_PAINT", value: "WALL_PAINT", type: "wall" },
  { label: "FLOOR_CARPET", value: "FLOOR_CARPET", type: "carpet" },
  { label: "FLOOR_TILE", value: "FLOOR_TILE", type: "tile" },
  { label: "FLOOR_WOOD", value: "FLOOR_WOOD", type: "wood" },
  { label: "FLOOR_VINYL", value: "FLOOR_VINYL", type: "wood" },
  { label: "FLOOR_LAMINATE", value: "FLOOR_LAMINATE", type: "wood" },
  { label: "RUG", value: "RUG", type: "rug" },
  { label: "WALLDECOR_ARTWORK", value: "WALLDECOR_ARTWORK", type: "walldecor" },
  { label: "WALLDECOR_MIRROR", value: "WALLDECOR_MIRROR", type: "walldecor" },
  { label: "WALLDECOR_CLOCK", value: "WALLDECOR_CLOCK", type: "walldecor" },
  {
    label: "FLOORDECOR_PLANT",
    value: "FLOORDECOR_PLANT",
    type: "floordecor",
  },
  { label: "FLOORDECOR_LAMP", value: "FLOORDECOR_LAMP", type: "floordecor" },
  {
    label: "TABLEDECOR_BASKET",
    value: "TABLEDECOR_BASKET",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_VASE",
    value: "TABLEDECOR_VASE",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_FIGURINE",
    value: "TABLEDECOR_FIGURINE",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_LANTERN",
    value: "TABLEDECOR_LANTERN",
    type: "tabledecor",
  },
  { label: "TABLEDECOR_LAMP", value: "TABLEDECOR_LAMP", type: "tabledecor" },
  {
    label: "TABLEDECOR_DECORATIVESPHERE",
    value: "TABLEDECOR_DECORATIVESPHERE",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_CLOCK",
    value: "TABLEDECOR_CLOCK",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_CROSS",
    value: "TABLEDECOR_CROSS",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_CANDLE",
    value: "TABLEDECOR_CANDLE",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_POTTEDPLANT",
    value: "TABLEDECOR_POTTEDPLANT",
    type: "tabledecor",
  },
];
export const vendorAndTypeOptions = (vendors, pathname, isType) => {
  const name = pathname.slice(0, pathname.length - 1).toLowerCase();
  const types = vendorTypes.filter(
    (item) => vendorTypetoGlobalType(item.label) === name
  );
  if (isType) {
    return types;
  } else {
    const onlyTypes = [];
    types.forEach((types) => onlyTypes.push(types.label));
    const optionsForVendors = [];
    vendors?.forEach((vendor) => {
      vendor.types?.forEach((item) => {
        if (onlyTypes.indexOf(item) >= 0 || pathname === "forms") {
          optionsForVendors.push({
            label: vendor.name,
            value: vendor.key,
            types: vendor.types,
          });
        }
      });
    });
    return Object.values(
      optionsForVendors.reduce(
        (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
        {}
      )
    );
  }
};
export const optionForVendor = (types, pathname) => {
  return vendorTypes.filter(
    (item) =>
      types?.indexOf(item) &&
      pathname.slice(0, pathname.length - 1) ===
        vendorTypetoGlobalType(item.label)
  );
};
export const vendorTypetoGlobalType = (type) => {
  return type
    .slice(0, type.length - type.split("").reverse().join("").indexOf("_") - 1)
    .toLowerCase();
};
export const checkVendorType = (route, types) => {
  if (types === "ADMIN") {
    return true;
  } else {
    const partName = activeTab(route)
      .toUpperCase()
      .slice(0, activeTab(route).length - 1);
    return types?.find((type) => {
      if (vendorTypetoGlobalType(type) === "walldecor" && route === "/walls") {
        return null;
      } else if (
        vendorTypetoGlobalType(type) === "floordecor" &&
        route === "/floors"
      ) {
        return null;
      } else {
        return type.includes(partName);
      }
    });
  }
};
export const onlyVendorTypes = (types) => {
  const arr = [];
  types.forEach((item) => arr.push(item.type));
  return arr;
};
