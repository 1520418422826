import React from "react";
import { Modal } from "antd";
import "./modal.css";

const ViewErrorModal = ({ open, setOpen }) => {
    const handleOk = () => {
        setOpen();
    };
    const handleCancel = () => {
        setOpen();
    };
    return (
        <div>
            <Modal
                title={""}
                open={open}
                width={700}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={""}
            >
                {open}
            </Modal>
        </div>
    );
};

export default ViewErrorModal;
