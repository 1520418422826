import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spin, Table } from "antd";
import "../LogsChart/logsCharts.css";
import { set_min_width_header } from "../../../Redux/Actions/commonAction";
import {
  getDebuggingInfo,
  startProcessingForDebugging,
} from "../../../Redux/Actions/transferDataAction";

const DebuggingInfo = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.common);
  const { debuggingInfo, loading } = state;
  const tableRef = useRef(null);
  let disable = false;

  debuggingInfo.forEach((item) =>
    !item.status || item.status === "In Progress" ? (disable = true) : null
  );
  const debuggingColumn = [
    {
      title: "Project",
      dataIndex: "projectName",
    },
    {
      title: "Branch",
      dataIndex: "branch",
    },
    {
      title: "Commit",
      dataIndex: "commit",
    },
    {
      title: "Author",
      dataIndex: "author",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
  ];

  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  useEffect(() => {
    dispatch(getDebuggingInfo());
  }, [dispatch]);
  return (
    <div className="logs">
      {loading.debuggingInfo ? (
        <div className={"chartLoading"}>
          <Spin size={"large"} />
        </div>
      ) : (
        <>
          <Button
            style={{ marginTop: "10px" }}
            disabled={disable}
            onClick={() => dispatch(startProcessingForDebugging())}
          >
            Processing
          </Button>
          {debuggingInfo.map((item) => (
            <div key={item.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>Version - {item.versionName}</h3>
                <h3>Status - {item.status ? item.status : "Not Completed"}</h3>
              </div>
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
                ref={tableRef}
                size={"small"}
                className={"globalTable"}
                rowKey={"commit"}
                // loading={loading}
                dataSource={item.commits}
                columns={debuggingColumn}
                bordered
              />
              {/*TODO folderneri pahna*/}
              {/*<a href={item.results.originalFolder}>Original Folder Url</a>*/}
              {/*<br />*/}
              {/*<a href={item.results.resultFolder}>Result Folder Url</a>*/}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DebuggingInfo;
