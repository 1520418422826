//validation utils
export const validateURL = (rule, value, callback) => {
  if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
    callback();
  } else {
    callback("Please enter a valid URL");
  }
};
export const validateNumber = (rule, value, callback) => {
  if (!value || value === "0") {
    callback("Value cannot be 0");
  } else if (/^\d+$/.test(value)) {
    callback();
  } else {
    callback("Please enter only digits");
  }
};
export const validateMaterialCode = (materials, value) => {
  let validate = true;
  materials.forEach((material) => {
    if (material.code === value) {
      validate = false;
    }
  });
  return validate;
};
