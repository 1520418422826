import React from "react";
import { Button, Form, Input, Typography } from "antd";
import "./auth.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Redux/Actions/auth";

const Auth = () => {
  const { Title } = Typography;
  const state = useSelector((state) => state.common);
  const { auth_info } = state;
  const dispatch = useDispatch();
  if (!auth_info) {
    localStorage.setItem("auth_info", "");
  }
  const onFinish = async (value) => {
    dispatch(login(value));
  };
  return (
    <div className={"loginForm"}>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        className={"passwordForm"}
      >
        <Title level={2}>DoSpace Admin</Title>
        <Form.Item
          label=""
          name="password"
          rules={[{ required: true, message: "Please input password!" }]}
          getValueFromEvent={(e) => e.target.value.trim()}
        >
          <Input.Password placeholder="input password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Log In
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Auth;
