import React, { useEffect, useRef } from "react";
import { Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateLinks } from "../../Redux/Actions/transferDataAction";
import {
  set_is_modal,
  set_min_width_header,
  set_tablesPageInfo,
} from "../../Redux/Actions/commonAction";
import { DeleteOutlined } from "@ant-design/icons";

const AffiliateLinks = () => {
  const state = useSelector((state) => state.common);
  const { loading, tablesPageInfo, affiliateLinks } = state;
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const tablePageOnChange = (e) => {
    dispatch(set_tablesPageInfo({ ...tablesPageInfo, forms: e }));
  };
  const affiliateLinksColumn = [
    {
      title: "ID",
      dataIndex: "key",
      sorter: (a, b) => b.key - a.key,
    },
    {
      title: "Key",
      dataIndex: "code",
    },
    {
      title: "Ratio",
      dataIndex: "ratio",
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (record) => (
        <div className="actions">
          <Tooltip title="Delete">
            <DeleteOutlined
              onClick={() => {
                dispatch(set_is_modal({ open: record, type: "delete" }));
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getAffiliateLinks());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  return (
    <div>
      <Table
        ref={tableRef}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        size={"small"}
        className={"globalTable"}
        loading={loading.affiliateLinks}
        dataSource={affiliateLinks}
        columns={affiliateLinksColumn}
        bordered
        onChange={tablePageOnChange}
        pagination={tablesPageInfo.forms}
      />
    </div>
  );
};

export default AffiliateLinks;
