import React from "react";
import { Modal, Table } from "antd";

const RecordsDetail = ({ open, setOpen }) => {
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  const dataSource = [];
  open[open.type].forEach((item, index) => {
    if (item) {
      item.key = item.id ? item.id : `${index + 1}`;
      dataSource.push(item);
    }
  });
  let columns = [
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: "Affiliate Key",
      dataIndex: "affiliateKey",
    },
    {
      title: "income",
      dataIndex: "income",
    },
    {
      title: "Interaction Source",
      dataIndex: "interactionSource",
    },
    {
      title: "Product Id",
      dataIndex: "productId",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Product Type",
      dataIndex: "productType",
    },
    {
      title: "timestamp",
      dataIndex: "timestamp",
    },
  ];
  return (
    <div>
      <Modal
        title={`View Records`}
        open={open}
        width={900}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          bordered
          size={"small"}
        />
      </Modal>
    </div>
  );
};

export default RecordsDetail;
