import Config from "./config";
import { queryParamsForCommonFilter } from "../helper/queryParamsAndResponseData";

class Decor extends Config {
  decorUrl(type = null, subType = null, params) {
    if (!!type && !!subType) {
      return `${
        this.server_domain_endpoint
      }/decors/filter?${queryParamsForCommonFilter(
        params
      )}&type=${type.toUpperCase()}&subType=${subType.toUpperCase()}`;
    }
    if (!!type && !subType) {
      return `${
        this.server_domain_endpoint
      }/decors/filter?${queryParamsForCommonFilter(
        params
      )}&type=${type.toUpperCase()}`;
    }
    return `${this.server_domain_endpoint}/decors`;
  }

  changeURL(id) {
    if (id) {
      return `${this.server_domain_endpoint}/decors/${id}`;
    }
    return `${this.server_domain_endpoint}/decors`;
  }
  decorPreproccess(id) {
    return `${this.server_domain_endpoint}/decors/preprocess/${id}`;
  }

  handlerWallFloorDecorData(type, data) {
    let wallDecorDataEx = {};
    if (Array.isArray(data)) {
      const newData = [];
      data.forEach((item) => {
        if (item.type === type) {
          wallDecorDataEx = {
            ...item,
            key: item.id,
            material: item.productImageURL,
            url: item.urlOnVendorPage,
            //todo formIds is not
            formIds: [],
          };
          newData.push(wallDecorDataEx);
        }
      });
      return newData;
    }

    wallDecorDataEx = {
      ...data,
      key: data.id,
      material: data.productImageURL,
      url: data.urlOnVendorPage,
      //todo formIds is not
      formIds: [],
    };
    return wallDecorDataEx;
  }
}

export default Decor;
