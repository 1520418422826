import {
  fetchingDataWithAxiosMiddleware,
  set_loading,
  set_response_notification,
  setInProgressMaterialLoading,
  setServerIsDown,
} from "./commonAction";
import Services from "../../Services";

const errorMessage = (type) => {
  if (type === "type") {
    return "Type & sub type is required";
  }
  if (type === "id") {
    return "Id is required";
  }
  return "Error message creator";
};

export const SET_TABLE_DECOR = "SET_TABLE_DECORS";
export const SET_FLOOR_DECOR = "SET_FLOOR_DECORS";
export const SET_WALL_DECOR = "SET_WALL_DECORS";

export const setTableDecor = (payload) => {
  return {
    type: SET_TABLE_DECOR,
    payload,
  };
};

export const setFloorDecor = (payload) => {
  return {
    type: SET_FLOOR_DECOR,
    payload,
  };
};

export const setWallDecor = (payload) => {
  return {
    type: SET_WALL_DECOR,
    payload,
  };
};

export const getDecors = (type, subType, filterData) => async (dispatch) => {
  try {
    dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: true })
    );

    const params = filterData
      ? filterData
      : JSON.parse(sessionStorage.getItem("filterParams"))[
          type?.toLowerCase() + "Decors"
        ];
    const response = await fetchingDataWithAxiosMiddleware(
      "GET",
      Services.Decor.decorUrl(type, subType, params)
    );
    const data = await response.data;
    switch (type?.toLowerCase()) {
      case "wall":
        dispatch(
          setWallDecor(
            Services.Decor.handlerWallFloorDecorData(
              "WALL",
              response.status === 204 ? [] : data
            )
          )
        );
        break;
      case "floor":
        dispatch(
          setFloorDecor(
            Services.Decor.handlerWallFloorDecorData(
              "FLOOR",
              response.status === 204 ? [] : data
            )
          )
        );
        break;
      case "table":
        dispatch(
          setTableDecor(
            Services.Decor.handlerWallFloorDecorData(
              "TABLE",
              response.status === 204 ? [] : data
            )
          )
        );
        break;
      default:
        dispatch(
          setWallDecor(
            Services.Decor.handlerWallFloorDecorData(
              "WALL",
              response.status === 204 ? [] : data
            )
          )
        );
        dispatch(
          setFloorDecor(
            Services.Decor.handlerWallFloorDecorData(
              "FLOOR",
              response.status === 204 ? [] : data
            )
          )
        );
        dispatch(
          setTableDecor(
            Services.Decor.handlerWallFloorDecorData(
              "TABLE",
              response.status === 204 ? [] : data
            )
          )
        );
        break;
    }
    return dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: false })
    );
  } catch (error) {
    if (error.message === "Network Error") {
      dispatch(setServerIsDown(true));
    }
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error ->" + error.message,
      })
    );
    return dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: false })
    );
  }
};

export const addDecor = (payload) => async (dispatch) => {
  try {
    dispatch(
      set_loading({ type: payload.type.toLowerCase() + "Decors", value: true })
    );
    if (!payload.type || !payload.subType) {
      throw new Error(errorMessage("type"));
    }
    const formData = new FormData();
    payload.type && formData.append("type", payload.type.toUpperCase());
    payload.name && formData.append("name", payload.name.trim());
    payload.subType &&
      formData.append("subType", payload.subType.toUpperCase());
    payload.vendorID && formData.append("vendorID", payload.vendorID);
    payload.url && formData.append("urlOnVendorPage", payload.url);
    payload.skuNumber && formData.append("skuNumber", payload.skuNumber);
    payload.material && formData.append("productImage", payload.material);
    payload.height && formData.append("height", payload.height);
    payload.width && formData.append("width", payload.width);
    payload.price && formData.append("price", payload.price);
    await fetchingDataWithAxiosMiddleware(
      "POST",
      Services.Decor.changeURL(),
      formData
    );
    dispatch(set_response_notification({ type: "success", text: "Success" }));
    dispatch(getDecors(payload.type));
  } catch (e) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error ->" + e.message,
      })
    );
    return dispatch(
      set_loading({ type: payload.type.toLowerCase() + "Decors", value: false })
    );
  }
};

export const editDecor = (payload) => async (dispatch) => {
  try {
    dispatch(
      set_loading({ type: payload.type.toLowerCase() + "Decors", value: true })
    );
    const formData = new FormData();
    payload.type && formData.append("type", payload.type.toUpperCase());
    payload.name && formData.append("name", payload.name.trim());
    payload.subType &&
      formData.append("subType", payload.subType.toUpperCase());
    payload.vendorID && formData.append("vendorID", payload.vendorID);
    payload.url && formData.append("urlOnVendorPage", payload.url);
    payload.skuNumber && formData.append("skuNumber", payload.skuNumber);
    payload.height && formData.append("height", payload.height);
    payload.width && formData.append("width", payload.width);
    payload.price && formData.append("price", payload.price);
    payload.material && formData.append("productImage", payload.material);
    await fetchingDataWithAxiosMiddleware(
      "PUT",
      Services.Decor.changeURL(payload.id),
      formData
    );
    dispatch(set_response_notification({ type: "success", text: "Success" }));
    dispatch(getDecors(payload.type));
  } catch (e) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error ->" + e.message,
      })
    );
    return dispatch(
      set_loading({ type: payload.type.toLowerCase() + "Decors", value: false })
    );
  }
};

export const deleteDecor = (id, type) => async (dispatch) => {
  try {
    dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: true })
    );
    if (!id) {
      throw new Error(errorMessage("id"));
    }
    if (!type) {
      throw new Error(errorMessage("type"));
    }
    await fetchingDataWithAxiosMiddleware(
      "DELETE",
      Services.Decor.changeURL(id)
    );
    dispatch(set_response_notification({ type: "success", text: "Success" }));
    dispatch(getDecors(type?.toUpperCase()));
  } catch (e) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error ->" + e.message,
      })
    );
    return dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: false })
    );
  }
};

export const prepoccessDecor = (id, type) => async (dispatch) => {
  try {
    dispatch(setInProgressMaterialLoading({ type: "decors", value: id }));
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${Services.Decor.decorPreproccess(id)}`
    );
    if (response.status) {
      const data = await response.data;
      if (data) {
        dispatch(
          set_response_notification({ type: "success", text: "Success" })
        );
        dispatch(getDecors(type));
      }
    }
    dispatch(setInProgressMaterialLoading({ type: "decors", value: false }));
  } catch (e) {
    dispatch(setInProgressMaterialLoading({ type: "decors", value: false }));
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + e.message,
      })
    );
  }
};
export const pause_decor = (data, type) => async (dispatch) => {
  try {
    dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: true })
    );
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${Services.Decor.changeURL()}/pause/${data}`
    );
    if (response?.status) {
      dispatch(set_response_notification({ type: "success", text: "Success" }));
      dispatch(getDecors(type?.toUpperCase()));
    }
  } catch (e) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + e.message,
      })
    );
    dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: false })
    );
  }
};
export const transform_decor = (data, type) => async (dispatch) => {
  try {
    dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: true })
    );
    const formData = new FormData();
    formData.append("decorID", data.decorID);
    formData.append("scale", data.scale);
    const response = await fetchingDataWithAxiosMiddleware(
      "POST",
      `${Services.Decor.changeURL()}/transform-decors/`,
      formData
    );
    if (response?.status) {
      dispatch(getDecors(type));
      dispatch(set_response_notification({ type: "success", text: "Success" }));
    }
  } catch (e) {
    dispatch(
      set_response_notification({
        type: "error",
        text: "Error -> " + e.message,
      })
    );
    dispatch(
      set_loading({ type: type?.toLowerCase() + "Decors", value: false })
    );
  }
};
