import React, { useState } from "react";
import { Button, Modal, Spin, Typography } from "antd";
import "./modal.css";
import { useDispatch } from "react-redux";
import { transform_floor } from "../../Redux/Actions/floor";
import maskSrc from "../../Assets/RoomMask.png";
import maskForRugs from "../../Assets/maskForRugs.png";
import onlyChairs from "../../Assets/onlyChairs.png";
import onlyFloor from "../../Assets/onlyFloor.png";
import maskForWallDecors from "../../Assets/maskForWallDecors.jpg";
import { useLocation } from "react-router-dom";
import { transform_rug } from "../../Redux/Actions/rugs";
import { transform_decor } from "../../Redux/Actions/decor";

const ZoomModal = ({ open, setOpen }) => {
  const location = useLocation();
  const { Title } = Typography;
  const [zoomSize, setZoomSize] = useState(
    location.pathname === "/floors" ? open.scale / 10 : 0.1
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(location.pathname === "/floors");
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  const deepdive = (e) => {
    setZoomSize(e.target.value);
  };
  const save = () => {
    if (location.pathname === "/floors") {
      dispatch(transform_floor({ floorTileID: open.key, zoom: zoomSize * 10 }));
    } else if (location.pathname === "/rugs") {
      dispatch(transform_rug({ decorID: open.key, scale: zoomSize * 10 }));
    } else if (location.pathname === "/wallDecors") {
      dispatch(
        transform_decor({ decorID: open.key, scale: zoomSize * 10 }, "wall")
      );
    } else if (location.pathname === "/floorDecors") {
      dispatch(
        transform_decor({ decorID: open.key, scale: zoomSize * 10 }, "floor")
      );
    } else if (location.pathname === "/tableDecors") {
      dispatch(
        transform_decor({ decorID: open.key, scale: zoomSize * 10 }, "table")
      );
    }
    setLoading(true);
    handleOk();
  };
  const mask =
    location.pathname === "/rugs"
      ? maskForRugs
      : location.pathname === "/floors"
      ? maskSrc
      : location.pathname === "/wallDecors" ||
        location.pathname === "/floorDecors" ||
        location.pathname === "/tableDecors"
      ? maskForWallDecors
      : "";
  const material =
    location.pathname === "/floors" ? open.floorUrl : open.productImageURL;
  return (
    <div>
      <Modal
        title={""}
        open={open}
        className={"zoomModal"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <div
          className={"zoomLoading"}
          style={{ display: loading ? "flex" : "none" }}
        >
          <Title>Wait a Minute</Title>
          <Spin size={"large"} />
        </div>
        <div
          className={"floorUrlForZoom"}
          style={{ opacity: loading ? "0.5" : "1" }}
        >
          <div style={{ width: "90%", overflow: "hidden" }}>
            <div style={{ perspective: 100 / (zoomSize * 10) }}>
              <img
                src={mask}
                alt="mask"
                // width={500}
                className={"roomMaskNonVisible"}
              />
              <img
                src={mask}
                alt="mask"
                // width={500}
                className={"roomMask"}
              />
              {location.pathname === "/rugs" ? (
                <>
                  <img
                    src={onlyChairs}
                    alt="mask"
                    // width={500}
                    className={"roomMaskNonVisible"}
                  />
                  <img
                    src={onlyChairs}
                    alt="mask"
                    // width={500}
                    className={"roomMask"}
                    style={{ zIndex: 4 }}
                  />
                  <img
                    src={onlyFloor}
                    alt="mask"
                    // width={500}
                    className={"roomMask"}
                    style={{ zIndex: 2 }}
                  />
                </>
              ) : (
                ""
              )}
              {location.pathname === "/rugs" ||
              location.pathname === "/floors" ? (
                <>
                  <img
                    src={material}
                    alt="logo"
                    onLoad={() => setLoading(false)}
                    style={{ display: "none" }}
                  />
                  <div
                    className={"floorForMask"}
                    style={{
                      padding:
                        location.pathname === "/floors"
                          ? `${100 / (zoomSize * 10)}rem`
                          : 0,
                      width: location.pathname === "/rugs" && "10vw",
                      height: location.pathname === "/rugs" && "13vh",
                      bottom: location.pathname === "/rugs" && "-20px",
                      backgroundSize: location.pathname === "/rugs" && "cover",
                      backgroundImage: `url('${material}')`,
                      transform:
                        location.pathname === "/rugs"
                          ? `scale(${zoomSize * 10}) rotateX(100deg)`
                          : ` scale(${zoomSize}) rotateX(7deg) rotate(-90deg)`,
                      transformStyle: "preserve-3d",
                      backgroundRepeat:
                        location.pathname === "/floors"
                          ? "repeat"
                          : "no-repeat",
                      zIndex: location.pathname === "/floors" ? 0 : 3,
                    }}
                  >
                    .
                  </div>
                </>
              ) : (
                <img
                  src={material}
                  alt="material"
                  style={{
                    position: "absolute",
                    width: open.width ? open.width : 25 / 4 + "vw",
                    top: location.pathname === "/wallDecors" && "10vh",
                    bottom:
                      (location.pathname === "/floorDecors" ||
                        location.pathname === "/tableDecors") &&
                      location.pathname === "/floorDecors"
                        ? `${zoomSize * 35}vh`
                        : `${14 + zoomSize * 10}vh`,
                    left: location.pathname === "/floorDecors" && "12vw",
                    transform: `scale(${zoomSize * 10})`,
                    transformStyle: "preserve-3d",
                    zIndex: 2,
                  }}
                />
              )}
            </div>
          </div>
          <input
            type="range"
            min="0.05"
            max="0.2"
            step="0.01"
            value={zoomSize}
            id="zoomer"
            onInput={deepdive}
          />
          <p>Scale {(zoomSize * 10).toFixed(1)}</p>
          {location.pathname === "/floors" ? (
            <p>
              Tile
              {open.tiles?.length ? (
                <span>
                  {" " +
                    (open.tiles[0]?.length * zoomSize * 10).toFixed(1) +
                    " "}
                </span>
              ) : (
                " empty "
              )}
              "
            </p>
          ) : (
            <>
              <p>
                Width
                {open.width ? (
                  <span>
                    {" " + (open.width * zoomSize * 10).toFixed(1) + " "}
                  </span>
                ) : (
                  " empty "
                )}
                "
              </p>
              <p>
                Height
                {open.height ? (
                  <span>
                    {" " + (open.height * zoomSize * 10).toFixed(1) + " "}
                  </span>
                ) : (
                  " empty "
                )}
                "
              </p>
            </>
          )}

          <Button onClick={save} type={"primary"}>
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ZoomModal;
